import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import { Head } from './Head';
import { PlayerMain } from './PlayerMain';
import { Context } from '../context';
import { Layout } from './Layout';
import { Tracks } from './Tracks';
import { Preloader } from './Preloader';
import { PlayerVideo } from './PlayerVideo';
import { Subtitles } from './Subtitles';
import { PlayerVideoSimple } from './PlayerVideoSimple';
import { Footer } from './Footer';
import './scrollbar-lite.css';

export function LayoutDragging() {
  const context = useContext(Context);
  // const { mediaId } = useParams();
  const location = useLocation();

  return (
    <Layout context={context}>

      {/* <Start /> */}

      <Head context={context} />

      {!context.state['mp3'] && <Preloader />}

      {/* <PlayerTop>
          <PlayerScrubbr>
          <PlayerChapters />
          </PlayerScrubbr>
        </PlayerTop> */}

      {Boolean(context.state['mp3']) &&
        context.state.videoPlayerType === 'simple' &&
        <PlayerVideoSimple context={context}>
          <Subtitles context={context} />
        </PlayerVideoSimple>}


      {Boolean(context.state['mp3']) &&
        context.state.videoPlayerType === 'advanced' &&
        <PlayerVideo context={context}>
          <Subtitles context={context} />
        </PlayerVideo>}



      {Boolean(context.state['mp3']) && <>
        <Tracks>
          <PlayerMain subtitleMode={context.state.subtitleMode} />
        </Tracks>
      </>}


      <Footer context={context} />

    </Layout>
  )
}
