import React, { useEffect, useState } from 'react'
import example_data from './audio/example_data.json';
import mp from './audio/torsunov.mp3';
import getCurrentState from './utils/getCurrentState';
import english_reel from './audio/english_reel.mp4'
import video_results from './audio/video_results.json';

const ReelsContext = React.createContext();

const debug = true;
const offline = false;



function ReelsProvider({ children }) {

  const [playing, setPlaying] = useState(false);
  const [video, setVideo] = useState('md')
  const [videoDuration, setVideoDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [zoomLevel, setZoomLevel] = useState(80)

  const [reelsFrame, setReelsFrame] = useState(true)

  const [videoAiOptions, setVideoAiOptions] = useState([])

  const [changed, setChanged] = useState([])
  const [playingFragment, setPlayingFragment] = useState()
  const [playingFragmentNext, setPlayingFragmentNext] = useState()

  const [browserVisible, setBrowserVisible] = useState(true)
  const [usedVideos, setUsedVideos] = useState()



  useEffect(() => {
    //TODO: сделать загрузку с сервера
    setVideoAiOptions(video_results)
  }, [])

  const parseData = (js) => {
    let parsed = js.map((one, ind, arr) => ({
      ...one,
      // id: one.topic.replaceAll(' ', '_') + '_' + one.topic_text.replaceAll(' ', '_'),
      start: one.start_time * 1000,
      end: one.end_time * 1000,
      length: (one.end_time - one.start_time) * 1000,
      chosenVideo: one.chosenVideo ? one.chosenVideo : one.relevant_videos[0].id,
      relevant_videos: [...one.relevant_videos
        .filter(oneF => oneF.video_url)
        .map(oneF => ({
        ...oneF,
          length: oneF.duration * 1000
      }))]
    }))




    parsed = parsed.map((one, ind, arr) => {

      // const prev = arr[ind - 1];
      // const next = arr[ind + 1];

      // if (next && prev) return ({
      //   ...one,
      //   lane:
      //     (prev.start + prev.length < one.start) && (next.start < (one.start + one.length))
      //       ? 2 : 1,
      // })

      // if (next && !prev) return ({
      //   ...one,
      //   lane: (next.start < (one.start + one.length))
      //     ? 2 : 1,
      // })
      // if (!next && prev) return ({
      //   ...one,
      //   lane: prev.start + prev.length < one.start ? 1 : 2,
      // })
      return ({
        ...one,
        lane: ind
      })



    })

    // console.log('parsed = ', parsed);
    return parsed

  }

  const tryParse = (string) => {
    try {
      string = JSON.parse(string)
      return string
    } catch (error) {
      console.log('wrong format');
      return false
    }
  }


  const getDataFromLocal = () => {
    let d = localStorage.getItem('reelsData')
    return d = Boolean(d) ? parseData(tryParse(d)) : parseData(videoAiOptions)
  }

  const [data, setDataInner] = useState([])

  useEffect(() => {
    if (videoAiOptions.length) setData(getDataFromLocal())
  }, [videoAiOptions])


  const setData = (newData = []) => {
    setDataInner(newData)
    const newChosen = []
    newData.forEach(one => {
      if (one.chosenVideo) {
        newChosen.push(one.chosenVideo)
      }
    })
    setUsedVideos(newChosen)

    localStorage.setItem('reelsData', JSON.stringify(newData));
  }

  const checkVideoUsed = (id) => {
    return usedVideos ? usedVideos.includes(id) : false
  }


  const addNewLane = (newLane = {}) => {

    newLane = {
      ...{
        // id: new Date().toTimeString(),
        // start: 0,
        start_time: 0,
        // end: 10000,
        length: 10000,
        end_time: 10,
        topic: '',
        topic_text: '',
        search_phrase: '',
        video_url: '',
        relevant_videos: []
      },
      ...newLane
    }

    // setCurrentState(prev => [newLane, ...prev])
    const prev = JSON.parse(JSON.stringify(data))
    setData([...parseData([newLane]), ...prev])
  }

  const removeLane = (id) => {
    const prev = JSON.parse(JSON.stringify(data))
    setData([...prev.filter(one => one.id !== id)])
  }

  useEffect(() => {
    const currentFragment = getCurrentState(data)
      .filter((one) => currentTime >= one.start && currentTime < one.end);;
    if (currentFragment.length) {

      const frag = data.filter(one => one.id === currentFragment[0].id)[0];
      //const getSelected = (frag) => {
      //  return frag.relevant_videos.filter(one => one.id === frag.chosenVideo)[0]
      //}
      //const url = getSelected(frag).video_url;
      //const currentId = getSelected(frag).id;
      setPlayingFragment(frag.id)
    } else {
      setPlayingFragment()
    }
  }, [currentTime])


  const getVideoUrlFromFragmentId = (id) => {
    const frag = data.filter(one => one.id === id)[0];
    const getSelected = (frag) => {
      return frag.relevant_videos.filter(one => one.id === frag.chosenVideo)[0]
    }
    const url = getSelected(frag).video_url;
    return url
  }

  const saveData = () => {
    localStorage.setItem('reelsData', JSON.stringify(data));
  };

  const addNewChange = (newData = { id: '', newStart: 0, newLength: 0, chosenVideo: '' }) => {
    let prevData = JSON.parse(JSON.stringify(data));
    prevData = prevData.map(one => {
      let newD = {};
      if (newData.id === one.id) {
        newD = { ...newData };
      }
      return { ...one, ...newD };
    });

    setData(prevData);
    if (newData.chosenVideo) { // in case chosenVideo is changing, save data. For fragment length changing, save data on mouse up event
      localStorage.setItem('reelsData', JSON.stringify(prevData));
    }
  };

  const setPlayStart = (id) => {
    // setChangeToSave(id)
    // setChanged((prevChanged) => {
    //   const newArray = [...prevChanged];
    //   const index = newArray.indexOf(id);
    //   if (index > -1) {
    //     newArray.splice(index, 1);
    //   }
    //   newArray.push(id);
    //   return newArray;
    // });
  };


  const handles = {
    getVideoUrlFromFragmentId,
    setChanged,
    setPlayingFragment,
    setPlayingFragmentNext,
    setZoomLevel,
    setPlaying,
    setVideo,
    setVideoDuration,
    setCurrentTime,
    addNewChange,
    saveData,
    setPlayStart,
    addNewLane,
    removeLane,
    setBrowserVisible,
    checkVideoUsed,
    setReelsFrame
  }

  return (<>
    <ReelsContext.Provider
      value={{
        fragments: data,
        usedVideos,
        videoDuration,
        video,
        videoAiOptions,
        state: {
          reelsFrame,
          browserVisible,
          reel: english_reel,
          currentTime,
          changed,
          playingFragment,
          playingFragmentNext,
          zoomLevel,
          playing,
          audioDuration: videoDuration,
          durationFull: videoDuration
        },
        handles
      }}
    >
      {children}
    </ReelsContext.Provider >
  </>
  )

}

const ReelsConsumer = ReelsContext.Consumer;

export { ReelsContext, ReelsProvider, ReelsConsumer };
