import React from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";

import { Provider } from '../context';
import { SubtitlesProvider } from '../contextSubtitles';
import { ReelsProvider } from '../contextReels';
import { Debug } from './Debug';
import { DragRange } from './DragRange';
import { LayoutDragging } from './LayoutDragging';
import { LayoutReels } from './LayoutReels';
import { LayoutSubtitles } from './LayoutSubtitles';
import { LayoutTranslate } from './LayoutTranslate';
import { ConfigProvider } from 'antd';
import reelsAntTheme from './Reels/reels-ant-theme.json';

export function RoutesMain(props) {



  return (
    <>

      <Router>
        <Routes>



          <Route
            path="/:mediaId"
            element={<>
              <Provider>
                <LayoutDragging />
                <Debug />
              </Provider>
            </>}
          />




          <Route
            exact path="/"
            element={
              // <Navigate to='/9020231925e711eebf6302420a000003?name=Олег_Геннадьевич_Торсунов_Короткая%20лекция&lang=english' />
              <Navigate to='/cldirx6z7000wvs0ykgx234jl?name=0057_Как_распознать_опасность_&lang=english&folder=2' />
            }
          />

          <Route
            exact path="/subtitles/:mediaId"
            element={
              <SubtitlesProvider>
                <LayoutSubtitles />
              </SubtitlesProvider>
            }
          />

          <Route
            exact path="/translate/:mediaId"
            element={
              <Provider>
                <LayoutTranslate />
              </Provider>
            }
          />


          <Route
            exsact path="/reels/"
            element={
              <ConfigProvider
                theme={reelsAntTheme}
              >
                <ReelsProvider>
                  <LayoutReels />
                </ReelsProvider>
              </ConfigProvider>
            }
          />


          <Route
            exact path="/subtitles/:mediaId"
            element={
              // <Navigate to='/9020231925e711eebf6302420a000003?name=Олег_Геннадьевич_Торсунов_Короткая%20лекция&lang=english' />
              <Navigate to='/cldirx6z7000wvs0ykgx234jl?name=0057_Как_распознать_опасность_&lang=english&folder=2' />
            }
          />




          <Route
            exact path="/t3"
            element={
              <DragRange />
            }
          />



        </Routes>


      </Router>


    </>
  )
}