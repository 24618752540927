import React from 'react';
import { ButtonH } from '../UiKit/ButtonH';
import { PopoverH } from '../UiKit/PopoverH';
import { Ico } from '../Uikit';

export const SpaceControls = React.memo((props) => {
    const { space, deleted, setDeleted, context } = props;
    const zoomLevel = 100 - context.state['zoomLevel'];
  
    const on = !deleted.some(one => one === space.nextTrackId);
  
    return (<div
        style={{
          position: 'absolute',
          left: `${space.start / zoomLevel}px`,
          width: `${(space.end - space.start) / zoomLevel}px`,
          height: '100%',
          zIndex: 100,
        }}>
      {on && <div className='_fv _f100 _fcc' style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#ff001172',
      }}>
  
        <PopoverH
          placement='bottom'
          title={<div className='_fv _f100 _fcc'>
            {on && <ButtonH onClick={() => {
              setDeleted([...deleted, space.nextTrackId]);
            }}>Удалить</ButtonH>}
          </div>}
        >
          <Ico>content_cut</Ico>
        </PopoverH>
      </div>}
      {!on && <div className='_fv _f100 _fcc' style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#00000011',
      }}>
        <div className='_fv _f100 _fcc _pointer x_voicing_cut0'
          style={{
            width: '100%',
            height: '100%'
          }}
          onClick={() => {
            setDeleted(deleted.filter(one => one !== space.nextTrackId));
          }}
        >
          <div className='x_voicing_cut0_icon_cut'>
            <Ico>content_cut</Ico>
          </div>
          <div className='x_voicing_cut0_icon_add'>
            <Ico>add</Ico>
          </div>
        </div>
      </div>}
    </div>)
})