import React, { useEffect, useState, useContext } from 'react';
import getCurrentState from '../utils/getCurrentState';
import { ReelsContext } from '../contextReels';

export function ReelsFragnmentPlayerNew({ fragmentPlayerRef }) {
  const context = useContext(ReelsContext);
  const [currentFragment, setCurrentFragment] = useState(null);

  useEffect(() => {
    return () => {
      if (fragmentPlayerRef.current) {
        fragmentPlayerRef.current.pause();
        fragmentPlayerRef.current.src = '';
        fragmentPlayerRef.current.load();
      }
    };
  }, []);

  useEffect(() => {
    const { currentTime } = context.state;
    const fragments = getCurrentState(context.fragments, currentTime)
      .filter((one) => currentTime >= one.start && currentTime < one.end);

    // Find the fragment that matches the current time
    const activeFragment = fragments.find(
      fragment => currentTime >= fragment.start && currentTime <= fragment.end
    );
    
    if (activeFragment?.id !== currentFragment?.id) {
      setCurrentFragment(activeFragment || null);
    }
  }, [context.state.currentTime]);

  useEffect(() => {
    if (currentFragment && fragmentPlayerRef.current) {
      if (context.state.playing) {
        fragmentPlayerRef.current.play();
      } else {
        fragmentPlayerRef.current.pause();
      }
    }
  }, [currentFragment]);

  return (
    <div className="c inact" style={{
      position: 'absolute',
      // visibility: context.state.playingFragment === playingFragment ? 'visible' : 'hidden',
      zIndex: 12,
      top: 0,
      right: 0,
      left: 0,
      // bottom: '4em',
      height: '500px',
      // bottom: 0,
      // border: '2px solid red',
      // backgroundColor: '#ff00003f'
    }}>

      {/* Fragment Video Player Overlay */}
      {currentFragment && (
        <video
          ref={fragmentPlayerRef}
          src={currentFragment.url}
          onError={(e) => console.error('Fragment video failed to load:', e)}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 10,
          }}
        />
      )}
    </div>
  );
}
