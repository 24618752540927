import { Popover, Tooltip } from 'antd';
import React, { useState } from 'react';


export const tooltipThemeDefault = {
  color: 'white',
  key: 'white',
  overlayInnerStyle: { color: 'black' },
  fontWeightStrong: 400,
}

export const TooltipH = (props) => {
  const {
    title,
    placement = 'top',
    tooltipTheme = {}
  } = { ...props }
  return < Tooltip
    title={title}
    placement={placement}
    {...tooltipThemeDefault}
    {...tooltipTheme}
    {...props}
  />
}