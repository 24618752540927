import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ReelsDragging } from './ReelsDragging';
import { ReelsVideoOptions } from './ReelsVideoOptions';
import { Time } from './Time';
import { PopoverH } from './UiKit/PopoverH';
import { TooltipH } from './UiKit/TooltipH';
import { Button, Tooltip, Popover } from 'antd';
import uniqid from "uniqid";
import { ButtonH } from './UiKit/ButtonH';
import { ReelsContext } from '../contextReels';
import { Ico } from './Uikit';
import { HorizontalSlider } from './HorizontalSlider';

export function ReelTracking() {
  const context = useContext(ReelsContext);
  const zoomLevel = 100 - context.state['zoomLevel'];
  const trackRef = useRef(null);
  const [optionsShown, setOptionsShown] = useState(false);
  const [order, setOrder] = useState(context.fragments.map(one => one.id));


  useEffect(() => {
    if (context.fragments.length !== order.length) {
      setOrder(context.fragments.map(one => one.id));
    }
  }, [context.fragments]);

  const blankTrackExist = context.fragments.some(one => !one.chosenVideo);

  return (
    <>
      <div className="x_tracks0 x_tracks0--videos">


        <div
          style={{ position: 'absolute', top: '1.4em', right: '2em', zIndex: 25 }}>
          <div className="_fv _fgap1 _fcc">

            <div className="_f">
              <Ico style={{ fontSize: '1.2em' }}>zoom_out</Ico>
              <HorizontalSlider
                value={context.state['zoomLevel']}
                min={10}
                max={90}
                width={200}
                // onValueChange={(val) =>
                //   Math.floor(context.handles['setActiveSegmentLength'](val + 1) / 10) * 10
                // }
                onValueChange={(val) => context.handles['setZoomLevel'](val)}
              />
              <Ico style={{ fontSize: '1.2em' }}>zoom_in</Ico>
            </div>
          </div>
        </div>


        <div className="x_track x_track--video" ref={trackRef}>
          <div className="x_track_playback inact" style={{
            zIndex: 10,
            opacity: 0.2,
            width: context.state.currentTime ? `${context.state.currentTime / zoomLevel + 5}px` : 'auto',
            mixBlendMode: 'multiply',
            left: '4em',
            position: 'absolute',
            zIndex: 1000,
          }} />

          <Time context={context} style={{ zIndex: 100, top: 0, left: '4em' }} />

          <div className="x_reeltracks_conteiner">
            <div className="x_track_all_order0">
              {!context.state.browserVisible && <div className="x_track_new"
                onClick={() => context.handles.setBrowserVisible(true)}
              >
                <div className={`x_track_new_button ${blankTrackExist ? 'inactive' : ''}`}>
                  <div className="t_icon t_icon--40">add</div>
                </div>
              </div>}

              {order.map(oneId => (

                <div
                  key={`popover_${oneId}_${uniqid()}`}
                  className="x_track_order"
                >

                  <TooltipH placement='right' title={<>Переместить дорожку вверх</>}>
                    <div className="x_track_order_up">
                      <div className="t_icon t_icon--60"><div>publish</div></div>
                    </div>
                  </TooltipH>

                  <TooltipH placement='right' title={<>Переместить дорожку вниз</>}>
                    <div className="x_track_order_up">
                      <div className="t_icon t_icon--60"><div>download</div></div>
                    </div>
                  </TooltipH>



                </div>

              ))}


              <ReelsDefaukltBgUpload />
            </div>

            <div className={'x_track_all_fragments x_track_all_fragments--reels'}
              style={{
                display: 'flex',
                width: context['videoDuration'] ? `${context['videoDuration'] / zoomLevel}px` : 'auto',
                height: 'auto',
              }}>
              {context.fragments.map((oneTrack, ind, arr) => (
                <Fragment key={`track_${oneTrack.id}_${ind}_${new Date().toTimeString()}`}>
                  <ReelsDragging
                    order={order.findIndex(one => one === oneTrack.id)}
                    {...oneTrack}
                    optionsShown={optionsShown}
                    context={context}
                    oneTrack={oneTrack}
                    ind={ind}
                    id={oneTrack.id}
                    start={oneTrack['start']}
                    length={oneTrack['length']}
                    tracks={context.fragments}
                    nextTrack={arr[ind + 1] ? arr[ind + 1] : null}
                    prevTrack={arr[ind - 1] ? arr[ind - 1] : null}
                    draggingTrack={trackRef}
                  >
                    <ReelsVideoOptions
                      optionsShown={optionsShown}
                      setOptionsShown={setOptionsShown}
                      handleNewOption={context.handles.addNewChange}
                      {...oneTrack}
                    />
                  </ReelsDragging>
                </Fragment>
              ))}
            </div>

            <div className="x_track_all_order0">


              {order.map(oneId => (
                <PopoverH
                  key={`popover_${oneId}_delete_${uniqid()}`}
                  title={<>
                    <ButtonH onClick={() => context.handles.removeLane(oneId)}>Удалить дорожку</ButtonH>
                  </>}>
                  <div className="x_track_order">
                    <Ico style={{ fontSize: '1.2em' }}>delete</Ico>
                  </div>
                </PopoverH>
              ))}



            </div>


          </div>
        </div>
      </div>
    </>
  );
}



function ReelsDefaukltBgUpload(props) {


  return (
    <>
      <TooltipH title={<>Загрузить фон по умолчанию</>} placement='right'>
        <div className="x_track_bg0"><div className="x_track_bg" /></div>
      </TooltipH>
    </>
  )
}
