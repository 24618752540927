import React from 'react';
import './range.css';


export const HorizontalSlider = ({ value = 0, onValueChange, width = '80px', min = 0, max = 99 }) => {
  const handleChange = (event) => {
    const newValue = Number(event.target.value);
    if (onValueChange) {
      onValueChange(newValue);
    }
  };

  return (
    <input
      type="range"
      min={min}
      max={max}
      value={value}
      onChange={handleChange}
      style={{ width }}
    />
  );
};
