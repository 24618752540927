import React, { useState, useContext } from 'react'

import './scrollbars-reels-menu.css';
import { ReelsContext } from '../../contextReels';
import { Ico } from '../Uikit';
import { PopoverH } from '../UiKit/PopoverH';
import { Input, Button } from 'antd';
import { CloudUploadOutlined, SearchOutlined } from '@ant-design/icons';


export function ReelsMenu(props) {

  const context = useContext(ReelsContext);

  return (
    <>
      {context.usedVideos && <div className="c_menu00">

        {!context.state.browserVisible && <div className="c_menu_menu_off"
          onClick={() => context.handles.setBrowserVisible(true)}
        >
          <div className="x_button x_button--icon x_button--ghost">
            <div className="ico ico--20">video_library</div>
          </div>
        </div>}


        <div className={`c_menu0 ${context.state.browserVisible ? 'active' : ''}`}>
          <div className="c_menu_menu">
            <div className="c_menu_menu_h" />
            <div className="c_menu_menu_c">
              <Button type="primary" icon={<CloudUploadOutlined />}>
                <div>Загрузить видео</div>
              </Button>

              <PopoverH title={
                <div className="_fv" style={{ width: '24em' }}>
                  <b>AI поиск</b>
                  <div>
                    <Input placeholder='Введите запрос' />
                  </div>
                  <div>
                    <Button type="primary">Найти</Button>
                  </div>
                </div>}>

                <Button type="primary" icon={<SearchOutlined />}>
                  AI поиск
                </Button>
              </PopoverH>
            </div>
            <div className="c_menu_menu_r">
              <div className="x_button x_button--icon x_button--ghost"
                onClick={() => context.handles.setBrowserVisible(false)}
              >
                <div className="ico ico--20">first_page</div>
              </div>
            </div>
          </div>
          <div className="c_menu">

            {context.videoAiOptions.map(one => (
              <ReelsBrowserOptions
                key={one.id}
                optionsShown={true}
                // handleNewOption={context.handles.addNewChange}
                {...one}
              >
                <ReelsOptionsInfo
                  topic={one.topic}
                  search_phrase={one.search_phrase}
                  topic_text={one.topic_text}
                />
              </ReelsBrowserOptions>
            ))}


          </div>
        </div>
      </div>}
    </>
  )
}





const ReelsBrowserOptions = (props) => {
  const {
    children,
    relevant_videos = [],
    parent = {}
  } = props

  return <>
    <div className="x_track_video_choose_item">

      {children}


      <div className="x_track_video_choose_ul">

        {relevant_videos
          .filter(one => one.video_url)
          .map((one, ind) => <ReelsBrowserOption
            id={one.id}
            key={`option_${one.id}`}
            parent={parent}
            video_url={one.video_url}
            duration={one.duration}
            thumbnail_url={one.thumbnail_url}
            title={one.title}
          />)}
      </div>


    </div>

  </>
}




export const ReelsOptionsInfo = ({
  topic = '',
  search_phrase = '',
  topic_text = ''
}) => {
  return <div className="x_track_video_options_info">
    <div className="x_track_video_options_line">
      <div className="x_track_video_options_label">
        <div>Топик:</div>
      </div>
      <div className="x_track_video_options_value">
        {topic}
      </div>
    </div>
    <div className="x_track_video_options_line">
      <div className="x_track_video_options_label">
        <div>Поисковая фраза:</div>
      </div>
      <div className="x_track_video_options_value">
        {search_phrase}
      </div>
    </div>
    <div className="x_track_video_options_line">
      <div className="x_track_video_options_label">
        Текст:
      </div>
      <div className="x_track_video_options_value ellipsis">
        {topic_text}
      </div>
    </div>
  </div>
}



export const ReelsBrowserOption = (props) => {

  const {
    id,
    added,
    title = '',
    parent = {},
    video_url,
    duration,
    thumbnail_url,
  } = props

  const context = useContext(ReelsContext);

  const handleClick = () => {
    // TODO: поправить добавку нового видео
    context.handles.addNewLane({
      ...parent,
      // id,
      // added,
      start_time: 0,
      length: duration,
      end_time: duration,
      // title,
      // relevance_score,
      chosenVideo: id,
      video_url: video_url,
      // thumbnail_url,
      // duration,
      // explanation,
      // relevant_videos: [...props.relevant_videos]
    })

  }

  return (<div className={`x_track_video_choose_li _hov0 ${context.handles.checkVideoUsed(id) ? 'added' : '_hov_op'}`}>
    {context.handles.checkVideoUsed(id) && <div className="x_track_video_added"><div className="t_icon">check</div></div>}

    {!context.handles.checkVideoUsed(id) && <div
      onClick={handleClick}
      className="x_track_video_choose_add0 _hov">
      <div className="x_button x_button--icon"><div className="t_icon">add</div></div></div>}



    <div className="x_track_video_choose_thumb"
      style={{ backgroundImage: `url("${thumbnail_url}")` }}
    />
    <div className="x_track_video_choose_info">
      <div className="x_track_video_choose_title">
        {title}
      </div>
    </div>
  </div>
  )
}