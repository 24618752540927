import React from 'react';
import { RoutesMain } from './components/RoutesMain';
import './components/add.css';
import './components/fonts.css';
import './components/main.css';


function App() {
  return (
    <div className="App b0">


          <RoutesMain />


    </div>
  );
}

export default App;
