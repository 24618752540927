import React, { Fragment, useEffect, useState, useContext } from 'react'
import { Link, withRouter, useNavigate, useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components';

import { HorizontalSlider } from './HorizontalSlider';
import { VolumeCross } from './VolumeCross';
import { PreloaderGif } from './Preloader';
import { ReelsContext } from '../contextReels';
import { Divider, Switch, theme } from 'antd';
import { Ico } from './Uikit';



const languages = {
  'english': {
    title: 'En',
    titleLong: 'английский'
  }
}





export function ReelsHead({ playerRef }) {

  const context = useContext(ReelsContext);

  const navigate = useNavigate();
  const location = useLocation();



  return (
    <>

      <div
        className='_f'
        style={{ position: 'absolute', top: '1em', left: '3em', zIndex: 25 }}>
        <ReelsPlay enter={true} context={context} playerRef={playerRef} />
      </div>



      <div

        style={{ position: 'absolute', top: '1em', right: '2em', zIndex: 25 }}>
        <div className="_fv _fgap1 _fcc">



          <div className="_f">
            <Switch
              theme={{
                "colorPrimary": "rgb(255,118,6)",
                "handleBg": "rgb(199,202,239)",
                "colorText": "rgb(132,134,163)",
                "colorTextQuaternary": "rgb(164,168,214)"
              }}
              checked={context.state['reelsFrame']}
              onChange={(val) => context.handles['setReelsFrame'](val)}
            />
            Reels рамка
          </div>


        </div>
      </div>

      {/* <div className="x_head0">
        <div className="x_head_border" />
        <div className="x_head_l">
          <div className="x_head_logo">
            <div className="x_player_control">
              <ReelsPlay enter={true} context={context} />
            </div>
            <div className="x_head_logo_text ellipsis">{fixName(context.state['name'])}</div>
            <div className="x_head_logo_lang">{fixLang(context.state['lang'])}</div>
          </div>
          <div className='x_head_center'>
          </div>
        </div>
        <div className="x_head_r">
        </div>
      </div> */}

    </>
  )
}





export function ReelsPlay({ enter = false, defaultAction = true, playerRef }) {

  const context = useContext(ReelsContext);

  useEffect(() => {
    if (enter) {

      const handleKeyPress = (e) => {
        if (e.key === " " && !context.state.pop) { // Spacebar key
          e.preventDefault();
          if (!context.state.playing) {
            playerRef.current.play();
          } else {
            playerRef.current.pause();
          }
          // context.handles.setPlayingFragment()
        }
      }
      document.addEventListener('keydown', handleKeyPress);

      // Clean up the event listener when the component is unmounted
      return () => {
        document.removeEventListener('keydown', handleKeyPress);
      }
    }
  }, [enter, context]);

  return (
    <>
      <div className="x_player_control_play"
        onClick={() => {
          if (!context.state.playing) {
            playerRef.current.play();
          } else {
            playerRef.current.pause();
          }
        }}
      >
        <div className="ico ico--40">
        {!context['state']['playing'] && <div>play_arrow</div>}
        {context['state']['playing'] && <div>pause</div>}
        </div>
      </div>

      <div className="x_player_control_play"
        onClick={() => {
          context.handles.setCurrentTime(0)
        }}
      >
        <div className="ico ico--40">
          <div>restart_alt</div>
        </div>
      </div>
    </>
  )
}
