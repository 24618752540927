const getCurrentState = (data) => {
    if (!data) return [];
  
    return data
      .map((one) => ({
        id: one.id,
        start: one.start,
        end: one.start + one.length,
        url: one.relevant_videos.length
          ? one.relevant_videos.find((frag) => frag.id === one.chosenVideo)?.video_url || ''
          : '',
      }));
  };
  
  export default getCurrentState;
  